import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'readable'})
export class ReadablePipe implements PipeTransform {
    transform(value: string): string {
        return value
            .replace('art. ', 'Articolo ')
            .replace('-comma 0', '')
            .replace('-comma', ' comma ');
    }
}
