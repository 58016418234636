import {NgModule} from '@angular/core';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {routerReducer, RouterReducerState, RouterState, StoreRouterConnectingModule} from '@ngrx/router-store';
import {AuthState, initialState as initAuthState, reducer as authReducer} from './auth/store/auth.reducer';
import {FineState, initialState as initFineState, reducer as fineReducer} from './fine/store/fine.reducer';
import {ChatbotState, initialState as initChatbotState, reducer as chatbotReducer} from '../features/chatbot/store/chatbot.reducer';
import {ChatState, initialState as initChatState, reducer as chatReducer} from '../features/advice/chat/store/chat.reducer';
import {HttpStatus, initialState as initHttpState, reducer as httpStatusReducer} from './httpStatus/store/http-status.reducer';
import {HeaderComponent} from './components/header/header.component';
import {EffectsModule} from '@ngrx/effects';
import {RouterEffects} from './router/store/router.effects';
import {AuthEffects} from './auth/store/auth.effects';
import {AuthService} from './auth/services/auth.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import * as Interceptors from './auth/services/auth.interceptor';
import {CookieService} from 'ngx-cookie-service';
import {CryptojsService} from './auth/services/cryptojs.service';
import {LoaderService} from './auth/services/loader.service';
import {HttpStatusComponent} from './httpStatus/http-status.component';
import {guards} from './guard';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {SharedModule} from '../shared/shared.module';
import {LoaderComponent} from '../shared/components/loader.component';
import {RoutingState} from './router/routingState';
import {HttpStatusInterceptor} from './httpStatus/store/http-status.interceptor';
import {HttpStatusEffects} from './httpStatus/store/http-status.effects';

export interface AppState {
    auth: AuthState;
    router: RouterReducerState;
    fine: FineState;
    chatbot: ChatbotState;
    chat: ChatState;
    httpStatus: HttpStatus;
}

@NgModule({
    declarations: [HeaderComponent, HttpStatusComponent],
    imports: [
        CommonModule,
        StoreModule.forRoot({
            auth: authReducer,
            router: routerReducer,
            fine: fineReducer,
            chatbot: chatbotReducer,
            chat: chatReducer,
            httpStatus: httpStatusReducer
        }, {
            runtimeChecks: {
                strictStateSerializability: true,
                strictStateImmutability: true,
                strictActionSerializability: true, // Da rivedere
                strictActionImmutability: true
            },
            initialState: {
                auth: initAuthState,
                router: {
                    state: {
                        url: window.location.pathname,
                        params: {},
                        queryParams: {}
                    },
                    navigationId: 0
                },
                fine: initFineState,
                chatbot: initChatbotState,
                chat: initChatState,
                httpStatus: initHttpState
            }
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25
        }),
        StoreRouterConnectingModule.forRoot({
            routerState: RouterState.Minimal
        }),
        EffectsModule.forRoot([
            RouterEffects, AuthEffects, HttpStatusEffects
        ]),
        NgxGoogleAnalyticsModule.forRoot('UA-44302035-14'),
        NgxGoogleAnalyticsRouterModule,
        SharedModule.forRoot()
    ],
    exports: [
        HeaderComponent, HttpStatusComponent, LoaderComponent
    ],
    providers: [
        AuthService,
        CookieService,
        CryptojsService,
        LoaderService,
        RoutingState,
        guards,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: Interceptors.AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: Interceptors.SignatureInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: Interceptors.UserInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: Interceptors.LoaderInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpStatusInterceptor, multi: true}
    ]
})
export class CoreModule {
}
