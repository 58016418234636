import {BrowserModule} from '@angular/platform-browser';
import {DoBootstrap, Injector, LOCALE_ID, NgModule} from '@angular/core';
import localeIt from '@angular/common/locales/it';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {LanguageTranslationModule} from './core/modules/language-translation.module';
import {HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import {BackButtonDisableModule} from 'angular-disable-browser-back-button';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {createCustomElement} from '@angular/elements';
import { T24Component } from './features/t24/t24.component';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import { ErrorComponent } from './features/error/error.component';

registerLocaleData(localeIt, 'it-IT');

@NgModule({
    declarations: [
        AppComponent,
        T24Component,
        ErrorComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        LanguageTranslationModule,
        CoreModule,
        BackButtonDisableModule.forRoot({
            preserveScrollPosition: true
        }),
        DeviceDetectorModule.forRoot(),
        /*GoogleTagManagerModule.forRoot({
            id: 'GTM-MDTBMTM'
        })*/
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'it-IT'}
    ],
    /* Per la build normale commentare entryComponents e decommentare bootstrap.
    * Viceversa se bisogna usare ngElements */
    // entryComponents: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule {
    /* Codice per far funzionare ngElements */
    /*constructor(private injector: Injector) {
        const myElement = createCustomElement(AppComponent, { injector: this.injector });
        customElements.define('app-element', myElement);
    }
    ngDoBootstrap() {}*/
    /* Fine codice */
}
