import {createAction, props} from '@ngrx/store';
import {Chat} from '../../../../model/chat';

export const loadChat = createAction(
    '[Chat] Init chat',
    props<{ partner_id: number, fine_id: number }>()
);

export const loadChatSuccess = createAction(
    '[Chat] Init chat success',
    props<{ chat: {status: string, messaggi: [Chat]} }>()
);

export const loadChatFailed = createAction(
    '[Chat] Init chat failed'
);

export const sendMsg = createAction(
    '[Chat] Send user message',
    props<{ partner_id: number, fine_id: number, message: string, attachments: any }>()
);

export const msgSent = createAction(
    '[Chat] Message sent',
    props<{ chat: {status: string, messaggio: Chat} }>()
);

export const msgUnsent = createAction(
    '[Chat] Message unsent'
);

export const chatSignature = createAction(
    '[Chat] Signature',
    props<{ message: Chat}>()
);

export const markAllRed = createAction(
    '[Chat] Read all messages'
);

export const markAllRedSuccess = createAction(
    '[Chat] Messages red '
);

export const markAllRedFailed = createAction(
    '[Chat] Messages not red'
);

export const getImagesUrl = createAction(
    '[Chat] Get images url',
    props<{ids: string}>()
);

export const getImagesUrlSuccess = createAction(
    '[Chat] Get images url success',
    props<{payload: {status: string, urls: [{id: number, url: string, type: string}]}}>()
);

export const getImagesUrlFailed = createAction(
    '[Chat] Get images url failed'
);

export const getMsgImagesUrl = createAction(
    '[Chat] Get message images url',
    props<{ids: string}>()
);

export const getMsgImagesUrlSuccess = createAction(
    '[Chat] Get message images url success',
    props<{payload: {status: string, urls: [{id: number, url: string, type: string}]}}>()
);

export const getMsgImagesUrlFailed = createAction(
    '[Chat] Get message images url failed'
);
