import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {AppState} from '../../core/core.module';
import * as FineActions from '../../core/fine/store/fine.actions';

@Component({
    selector: 'app-edit-price',
    template: `
        <div class="modal-header">
            <h4 class="title-brand">Modifica importo multa</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form class="mt-3" [formGroup]="priceForm" (ngSubmit)="editPrice()">
            <div class="modal-body text-center">
                <p>Nel caso in cui l'importo della multa riportato non sia corretto, ti preghiamo di inserire l'importo corretto riportato nel verbale.</p>
                <div class="form-row">
                    <div class="form-group col">
                        <label for="price_min" class="text-left d-block">Importo ridotto</label>
                        <input formControlName="price_min" type="text" class="form-control" autofocus>
                        <div class="form-text text-left">Inserisci l'importo più basso della multa</div>
                    </div>
                    <div class="form-group col">
                        <label for="price_max" class="text-left d-block">Importo pieno</label>
                        <input formControlName="price_max" type="text" class="form-control" autofocus>
                        <div class="form-text text-left">Inserisci l'importo più alto della multa</div>
                    </div>
                </div>
                <p class="info-text"><small>N.b: per importi con cifre decimali utilizzare il punto e non la virgola.</small></p>
            </div>
            <div class="modal-footer ">
                <button type="submit" class="btn btn-sm btn-brand" [disabled]="!priceForm.valid">Modifica</button>
            </div>
        </form>
    `,
    styles: [``]
})
export class EditPriceComponent implements OnInit {
    @Input() price_min: number;
    @Input() price_max: number;

    priceForm: FormGroup;


    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private store: Store<AppState>
    ) {
    }

    ngOnInit() {
        if (this.price_min === null) {
            this.price_min = 0;
        }

        if (this.price_max === null) {
            this.price_max = 0;
        }

        this.priceForm = this.fb.group({
            price_min: [this.price_min, Validators.pattern('^\\d{0,8}(\\.\\d{1,4})?$')],
            price_max: [this.price_max, Validators.pattern('^\\d{0,8}(\\.\\d{1,4})?$')]
        });
    }

    editPrice() {
        const price_min = this.priceForm.get('price_min').value;
        const price_max = this.priceForm.get('price_max').value;
        this.store.dispatch(FineActions.editPrice({price_min, price_max}));
        this.activeModal.close();
    }

}
