import {Component, OnInit} from '@angular/core';
import {InitAuth} from '../../model/auth';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../core/core.module';
import * as IntroActions from '../intro/store/intro.actions';

@Component({
    selector: 'app-t24',
    template: ``,
    styles: []
})
export class T24Component implements OnInit {
    email: string;
    partner_id = 1;
    sub: any;
    initPayload: InitAuth;

    constructor(
        private route: ActivatedRoute,
        private store: Store<AppState>
    ) {
    }

    ngOnInit(): void {
        this.sub = this.route.params
            .subscribe(params => {
                    this.email = params['email'];
                    this.login();
                }
            );
        document.body.classList.add('t24');
    }

    login() {
        // Preparo payload per login
        this.initPayload = {
            data: {username: atob(this.email)},
            username: atob(this.email),
            partner_id: this.partner_id
        };
        this.store.dispatch(IntroActions.initT24({init: this.initPayload}));
    }

}
