import {AppState} from '../../core.module';
import {createSelector} from '@ngrx/store';
import {AuthState} from './auth.reducer';

export const selectAuth = (state: AppState) => state.auth;

export const getToken = createSelector(
    selectAuth,
    (state: AuthState) => state.values?.access_token
);

export const getTokenDate = createSelector(
    selectAuth,
    (state: AuthState) => state.values.expires_at
);

export const getUsername = createSelector(
    selectAuth,
    (state: AuthState) => state.values.username
);

export const getUserId = createSelector(
    selectAuth,
    (state: AuthState) => state.values.user_id
);

export const getPartnerId = createSelector(
    selectAuth,
    (state: AuthState) => state.values.partner_id
);

export const getDataPayload = createSelector(
    selectAuth,
    (state: AuthState) => state.data
);

export const emailUpdated = createSelector(
    selectAuth,
    (state: AuthState) => state.email_updated
);

export const getTerms = createSelector(
    selectAuth,
    (state: AuthState) => state.terms
);


