import {Component, OnInit} from '@angular/core';
import {selectHttpStatus} from './core/httpStatus/store/http-status.selectors';
import {select, Store} from '@ngrx/store';
import {AppState} from './core/core.module';
import {DeviceDetectorService} from 'ngx-device-detector';
import {NavigationEnd, Router} from '@angular/router';
import {GoogleTagManagerService} from 'angular-google-tag-manager';

@Component({
    selector: 'app-root',
    template: `
        <app-http-status [httpStatus]="httpStatus$ | async"></app-http-status>
        <app-loader></app-loader>
        <app-header></app-header>
        <main class="container">
            <router-outlet></router-outlet>
        </main>
    `,
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    httpStatus$ = this.store.pipe(select(selectHttpStatus));
    deviceInfo = null;

    constructor (
        private store: Store<AppState>,
        private deviceService: DeviceDetectorService,
        // private router: Router,
        // private gtmService: GoogleTagManagerService
    ) {
        this.mobileDetector();
    }

    ngOnInit() {
        // this.router.events.forEach(item => {
        //     if (item instanceof NavigationEnd) {
        //         const gtmTag = {
        //             event: 'page',
        //             pageName: item.url
        //         };
        //
        //         this.gtmService.pushTag(gtmTag);
        //     }
        // });
    }

    mobileDetector() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        if (isMobile && this.deviceInfo.browser === 'Safari') {
            document.querySelector('body').classList.add('mobile-safari');
        }
    }
}
