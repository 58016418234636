import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import * as errorActions from './http-status.actions';
import {Router} from '@angular/router';

@Injectable()
export class HttpStatusEffects {

    badError$ = createEffect(
        () => this.actions$.pipe(
            ofType(errorActions.badError),
            tap(
                () => this.router.navigate(['error'])
            )
        ), {
            dispatch: false
        }
    );


    constructor(
        private actions$: Actions,
        private router: Router
    ) {
    }
}
