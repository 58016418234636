import {Component, Input} from '@angular/core';
import {Fine, Score} from '../../model/fine';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-table-details',
  template: `
      <div class="modal-header">
          <h4 class="title-brand">
              <span *ngIf="type === 'pay'">Se scegli di pagare</span>
              <span *ngIf="type === 'appeal'">Se scegli di fare ricorso</span>
          </h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body table-details">
          <div class="table-responsive">
              <p class="table-hint" *ngIf="type === 'pay'">Di seguito puoi vedere quanto ti costerebbe pagare la multa entro 5 o 60 giorni. Se qualche campo dovesse mancare, completalo nella tabella sottostante modificando la relativa voce.</p>
              <p class="table-hint" *ngIf="type === 'appeal'">Di seguito puoi valutare quanto andresti a pagare in caso di ricorso andato o meno a buon fine. Presta molta attenzione alla percentuale di successo. Se qualche campo dovesse mancare, completalo nella tabella sottostante modificando la relativa voce.</p>
              <table class="table" *ngIf="type === 'pay'">
                  <thead>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="col">&nbsp;</th>
                      <th class="col" scope="col" *ngIf="this.days_remaining > 0">{{ 'table.table_pay.col_1' | translate }}{{fine.prezzo_immediato_data_scadenza | date: 'dd/MM/yyyy'}}</th>
                      <th class="col" scope="col">{{ 'table.table_pay.col_2' | translate }}{{fine.prezzo_normale_data_scadenza | date: 'dd/MM/yyyy'}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="row">{{ 'table.table_pay.row_1' | translate }}</th>
                      <td class="col" *ngIf="this.days_remaining > 0">{{fine.prezzo_immediato !== undefined ? '€ ' + (fine.prezzo_immediato | currency: 'EUR' : '') : '- -'}}</td>
                      <td class="col">{{fine.prezzo_normale !== undefined ? '€ ' + (fine.prezzo_normale | currency: 'EUR' : '') : '- -'}}</td>
                  </tr>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="row">{{ 'table.table_pay.row_2' | translate }}</th>
                      <td class="col" *ngIf="this.days_remaining > 0">€ 2</td>
                      <td class="col">€ 2</td>
                  </tr>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="row">{{ 'table.table_pay.row_3' | translate }}</th>
                      <ng-container [ngSwitch]="fine.punti">
                          <ng-container *ngSwitchCase="null">
                              <td class="col" *ngIf="this.days_remaining > 0">- -</td>
                              <td class="col">- -</td>
                          </ng-container> 
                          <ng-container *ngSwitchCase="0">
                              <td class="col" *ngIf="this.days_remaining > 0">{{fine.punti}}</td>
                              <td class="col">{{fine.punti}}</td>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                              <td class="col" *ngIf="this.days_remaining > 0">-{{fine.punti}}</td>
                              <td class="col">-{{fine.punti}}</td>
                          </ng-container>
                      </ng-container>
                  </tr>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="row">{{'table.table_pay.row_4' | translate }}</th>
                      <td class="col" *ngIf="this.days_remaining > 0">
                          <b>{{fine.prezzo_immediato !== undefined ? '€ ' + ((+fine.prezzo_immediato + 2) | currency: 'EUR' : '') : '- -'}} <span *ngIf="fine.punti > 0">+ {{fine.punti}}pt</span></b>
                      </td>
                      <td class="col">
                          <b>{{fine.prezzo_normale !== undefined ? '€ ' + ((+fine.prezzo_normale + 2) | currency: 'EUR' : '') : '- -'}} <span *ngIf="fine.punti > 0">+ {{fine.punti}}pt</span></b>
                      </td>
                  </tr>
                  </tbody>
              </table>

              <table class="table" *ngIf="type === 'appeal'">
                  <thead>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="col">&nbsp;</th>
                      <th class="col" scope="col">{{ 'table.table_appeal.col_1' | translate }}</th>
                      <th class="col" scope="col">{{ 'table.table_appeal.col_2' | translate }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="row">{{ 'table.table_appeal.row_1' | translate }}</th>
                      <td class="col">{{score.score}}%</td>
                      <td class="col">{{100 - score.score}}%</td>
                  </tr>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="row">{{ 'table.table_appeal.row_2' | translate }}</th>
                      <td class="col">
                          {{score.ricorso.prezzo !== undefined ? '€ ' + (+score.ricorso.prezzo | currency: 'EUR' : '') : '- -'}}
                      </td>
                      <td class="col">
                          {{score.ricorso.prezzo !== undefined ? '€ ' + (+score.ricorso.prezzo | currency: 'EUR' : '') : '- -'}}
                      </td>
                  </tr>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="row">{{ 'table.table_appeal.row_3' | translate }}</th>
                      <td class="col">0</td>
                      <td class="col">{{fine.prezzo_normale !== undefined ? '€ ' + (fine.prezzo_normale * 2 | currency: 'EUR' : '') : '- -'}}</td>
                  </tr>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="row">{{'table.table_appeal.row_4' | translate }}</th>
                      <ng-container [ngSwitch]="fine.punti">
                          <ng-container *ngSwitchCase="null">
                              <td class="col">- -</td>
                              <td class="col">- -</td>
                          </ng-container>
                          <ng-container *ngSwitchCase="0">
                              <td class="col">{{fine.punti}}</td>
                              <td class="col">{{fine.punti}}</td>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                              <td class="col">-{{fine.punti}}</td>
                              <td class="col">-{{fine.punti}}</td>
                          </ng-container>
                      </ng-container>
                  </tr>
                  <tr class="d-flex align-items-center">
                      <th class="col" scope="row">{{'table.table_appeal.row_5' | translate }}</th>
                      <td class="col text-bold">
                          <b>{{score.ricorso.prezzo !== undefined ? '€ ' + (+score.ricorso.prezzo | currency: 'EUR' : '') : '- -'}}</b>
                      </td>
                      <td class="col text-bold">
                          <b>{{fine.prezzo_normale !== undefined ? '€ ' + ((+fine.prezzo_normale * 2) + (+score.ricorso.prezzo) | currency: 'EUR' : '') : '- -'}} <span *ngIf="fine.punti > 0">+ {{fine.punti}}pt</span></b>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>
      </div>
  `,
  styles: [
  ]
})
export class TableDetailsComponent {
    @Input() fine: Fine;
    @Input() score: Score;
    @Input() paid: boolean;
    @Input() type: string;
    @Input() days_remaining: number;

  constructor(
      public activeModal: NgbActiveModal
  ) { }


}
