import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-chat-alert',
  template: `
      <div class="modal-header">
          <h4 class="title-brand">Hai {{msg_number}} {{msg_number > 1 ? 'nuovi messaggi' : 'nuovo messaggio'}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <p>Il consulente che ha preso in carico la sua multa ha bisogno di mettersi in contatto con lei.<br>Per leggere i messaggi ricevuti clicchi in alto sull'icona <i-feather name="message-square"></i-feather>.</p>
      </div>
  `,
  styles: [
  ]
})
export class ChatAlertComponent implements OnInit {
    @Input() msg_number: number;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
