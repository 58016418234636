import {AppState} from '../../../core/core.module';
import {createSelector} from '@ngrx/store';
import {ChatbotState} from './chatbot.reducer';
import {takeLast} from 'rxjs/operators';

export const selectChatbot = (state: AppState) => state.chatbot;

export const getMessages = createSelector(
    selectChatbot,
    (state: ChatbotState) => state.messages
);

export const getSessionId = createSelector(
    selectChatbot,
    (state: ChatbotState) => state.sessionId
);

export const getLoaderStatus = createSelector(
    selectChatbot,
    (state: ChatbotState) => state.loader
);

export const chatEnded = createSelector(
    selectChatbot,
    (state: ChatbotState) => state.ended
);
