import {createAction, props} from '@ngrx/store';
import {Auth} from '../../../model/auth';

export const saveAuth = createAction(
    '[Auth] Save',
    props<{ auth: Auth }>()
);

export const setNewUser = createAction(
    '[Auth] Set user',
    props<{ email: string}>()
);

export const setNewUserSuccess = createAction(
    '[Auth] Set user success',
    props<{user: any}>()
);

export const setNewUserFailed = createAction(
    '[Auth] Set user failed'
);

export const setNewUserRented = createAction(
    '[Auth] Set user rented',
    props<{ email: string}>()
);
