import {Action, createReducer, on} from '@ngrx/store';
import * as ErrorActions from './http-status.actions';

export interface HttpStatus {
    type: string;
    message: string;
    status: string;
    gravity?: string;
    closeBtn?: boolean;
}


export const initialState: HttpStatus = {
    type: '',
    message: '',
    status: 'ok',
    gravity: 'danger',
    closeBtn: true
};

const httpStatusReducer = createReducer(
    initialState,
    on(ErrorActions.error500, (state, action) => ({
        type: action.error.type,
        message: action.error.message,
        status: action.error.status,
        gravity: 'danger',
        closeBtn: false
    })),
    on(ErrorActions.lightError, (state, action) => ({
        type: action.error.type,
        message: action.error.message,
        status: action.error.status,
        gravity: 'warning',
        closeBtn: true
    })),
    on(ErrorActions.resetError, () => ({
        type: '',
        message: '',
        status: 'ok'
    }))
);

export function reducer(state: HttpStatus | undefined, action?: Action) {
    return httpStatusReducer(state, action);
}
