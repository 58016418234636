import {Auth} from '../../../model/auth';
import {Action, createReducer, on} from '@ngrx/store';
import * as AuthActions from './auth.actions';
import * as IntroActions from '../../../features/intro/store/intro.actions';
import * as LoadActions from '../../../features/load/store/load.actions';
import * as ChatbotActions from '../../../features/chatbot/store/chatbot.actions';
import * as FineActions from '../../fine/store/fine.actions';
import * as ChatActions from '../../../features/advice/chat/store/chat.actions';
import * as BraintreeActions from '../../../shared/components/braintree/braintree.actions';

export interface AuthState {
    data?: any;
    values?: Auth;
    terms?: boolean;
    email_updated?: boolean;
    error?: boolean;
}

export const initialState: AuthState = {};

const authReducer = createReducer(
    initialState,
    on(IntroActions.initApp, (state, action) => ({
        data: action.init.data,
        values: {
            ...state.values,
            username: action.init.username,
            partner_id: action.init.partner_id
        },
        terms: false,
        email_updated: false,
        error: false
    })),
    // Uguale a IntroActions.initApp
    on(IntroActions.initAppResume, (state, action) => ({
        data: action.init.data,
        values: {
            ...state.values,
            username: action.init.username,
            partner_id: action.init.partner_id
        },
        email_updated: true,
        error: false
    })),
    // Uguale a initAppResume ma per T24
    on(IntroActions.initT24, (state, action) => ({
        data: action.init.data,
        values: {
            ...state.values,
            username: action.init.username,
            partner_id: action.init.partner_id
        },
        email_updated: true,
        error: false
    })),
    on(IntroActions.initAppSuccess, (state, action) => ({
        ...state,
        data: [],
        values: {
            ...state.values,
            user_id: action.auth.user_id,
            access_token: action.auth.access_token,
            expires_at: action.auth.expires_at
        },
        error: false
    })),
    on(IntroActions.terms, (state, action) => ({...state, terms: action.terms})),
    on(AuthActions.saveAuth, (state, action) => ({
            data: action.auth,
            values: {
                ...state.values,
                username: action.auth.username,
                partner_id: action.auth.partner_id,
                user_id: action.auth.user_id,
                access_token: action.auth.access_token,
                expires_at: action.auth.expires_at
            },
            terms: true,
            error: false
        })
    ),
    on(LoadActions.loadSignature, (state, action) => ({...state, data: {'content-type': action.data}})),
    on(LoadActions.uploadFineDone, (state) => ({...state, data: []})),
    // Uguale a LoadAction.uploadFineDone
    on(FineActions.resumeChatbot, (state) => ({...state, data: []})),
    on(ChatbotActions.chatSignature, (state, action) => ({
        ...state,
        data: {
            query: action.msg,
            sessionId: action.session_id
        }
    })),
    on(ChatbotActions.setOwnershipRent, (state, action) => ({...state, data: {'ownership': +action.ownership}})),
    on(ChatbotActions.setOwnershipOther, (state, action) => ({...state, data: {'ownership': +action.ownership}})),
    on(ChatbotActions.loadChatbot, (state) => ({...state, data: []})),
    on(AuthActions.setNewUser, (state, action) => ({...state, data: {'email': action.email}})),
    on(AuthActions.setNewUserRented, (state, action) => ({...state, data: {'email': action.email}})),
    on(AuthActions.setNewUserSuccess, (state, action) => ({
        ...state,
        values: {
            ...state.values,
            username: action.user[0][0].email,
            user_id: action.user[1].user_id,
            access_token: action.user[1].access_token,
            expires_at: action.user[1].expires_at
        },
        email_updated: true
    })),
    on(FineActions.editPoints, (state, action) => ({
        ...state,
        data: {
            points: action.points
        }
    })),
    on(FineActions.editPrice, (state, action) => ({
        ...state,
        data: {
            prezzo_immediato: action.price_min,
            prezzo_normale: action.price_max
        }
    })),
    on(FineActions.getPayTipperKey, (state, action) => ({
        ...state,
        data: {
            type: action.choice
        }
    })),
    on(FineActions.goToPaytipper, (state, action) => ({
        ...state,
        data: {
            firstname: (action.payload.nome ? action.payload.nome : action.payload.nome_pa),
            lastname: (action.payload.cognome ? action.payload.cognome : action.payload.cognome_pa)
        }
    })),
    on(FineActions.confirmAppeal, (state) => ({
        ...state,
        data: []
    })),
    on(FineActions.sendReason, (state, action) => ({
        ...state,
        data: {
            motivazione: action.reason
        }
    })),
    on(ChatActions.chatSignature, (state, action) => ({
        ...state,
        data: {
            messaggio: action.message.testo,
            allegati: action.message.allegati
        }
    })),
    on(BraintreeActions.setNonce, (state, action) => ({
        ...state,
        data: {
            nonce: action.nonce
        }
    }))
);

export function reducer(state: AuthState | undefined, action: Action) {
    return authReducer(state, action);
}
