import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {AppState} from '../core.module';

import * as RouterActions from '../router/store/router.actions';
import * as FineSelectors from '../fine/store/fine.selectors';

@Injectable()
export class ChatbotGuard implements CanActivate {
    loaded: boolean;

    constructor (
        private store: Store<AppState>
    ) { }

    canActivate() {
        this.store.pipe(select(FineSelectors.getLoadedState)).subscribe(res => this.loaded = res);
        if (!this.loaded) {
            this.store.dispatch(RouterActions.go({path: ['intro']}));
            return false;
        } else {
            return true;
        }
    }

}
