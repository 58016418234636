import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../core.module';
import * as errorActions from './http-status.actions';
import * as routerActions from '../../router/store/router.actions';

@Injectable()
export class HttpStatusInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<AppState>
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 400:
                        switch (error.error.type) {
                            case 'user/sync_error':
                            case 'names/insert':
                            case 'urls/generate':
                            case 'ocr/runtime':
                            case 'body/invalid':
                                // Se quando mostro lo score non c'è l'ID
                                // Quando creo un attachment di un tipo non supportato
                                // Controllo se attachment esiste
                            case 'rent/insert':
                            case 'price/insert':
                            case 'chat/missing-params':
                                // Quando si va al Resume e manca uno dei parametri, solitamente l'ID
                            case 'chat/missing-input':
                                // Ripetere l'ultima chiamata al clikc della X
                            case 'multa/messaggio':
                            case 'motivation/insert':
                            case 'appeal/request':
                            default:
                                this.store.dispatch(errorActions.lightError({error: error.error}));
                                break;
                        }
                        break;
                    case 401:
                        switch (error.error.type) {
                            case 'score/needs-payment':
                                this.store.dispatch(routerActions.go({path: ['scoring/payment']}));
                                break;
                            default: this.store.dispatch(errorActions.badError());
                                break;
                        }
                        break;
                    case 404:
                        this.store.dispatch(errorActions.error500({error: error.error}));
                        break;
                    case 500:
                        this.store.dispatch(errorActions.error500({error: error.error}));
                        break;
                    default:
                        this.store.dispatch(errorActions.error500({error: error.error}));
                        break;
                }
                return throwError(error.message);
            })
        );
    }

}
