import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../../core.module';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, switchMap, take} from 'rxjs/operators';
import * as AuthSelectors from '../store/auth.selectors';
import {CryptojsService} from './cryptojs.service';
import {LoaderService} from './loader.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<AppState>
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.store.select(AuthSelectors.getToken)
            .pipe(
                take(1),
                switchMap(token => {
                    const clonedReq = !!token ? req.clone({setHeaders: {Authorization: 'Bearer ' + token}}) : req;
                    return next.handle(clonedReq)
                        .pipe(
                            catchError(err => {
                                return throwError(err);
                            })
                        );
                })
            );
    }
}

@Injectable()
export class SignatureInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<AppState>,
        private cryptojsService: CryptojsService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.store.select(AuthSelectors.getDataPayload)
            .pipe(
                take(1),
                switchMap(dataPayload => {
                    if (req.method === 'GET') {
                        dataPayload = '';
                    }
                    const user_crypted = this.cryptojsService.signRequest(dataPayload);
                    const clonedReq = !!user_crypted ? req.clone({setHeaders: {signature: user_crypted}}) : req;
                    return next.handle(clonedReq)
                        .pipe(
                            catchError(err => {
                                return throwError(err);
                            })
                        );
                })
            );
    }
}

@Injectable()
export class UserInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<AppState>
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.store.select(AuthSelectors.getUserId)
            .pipe(
                take(1),
                switchMap(user_id => {
                    const clonedReq = !!user_id ? req.clone({setHeaders: {User: '' + user_id}}) : req;
                    return next.handle(clonedReq)
                        .pipe(
                            catchError(err => {
                                return throwError(err);
                            })
                        );
                })
            );
    }
}

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(public loaderService: LoaderService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.show();
        return next.handle(req).pipe(
            finalize(() => this.loaderService.hide())
        );
    }
}
