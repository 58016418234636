import {Component, Input, OnInit} from '@angular/core';
import {Score} from '../../model/fine';

@Component({
    selector: 'app-scoring',
    template: `
        <label class="label mt-3 ">
            <ng-container *ngIf="score.advice.responso === ''">
                {{ 'scoring.response.label_scoring_1'  | translate }}
            </ng-container>
            <ng-container *ngIf="score.advice.responso !== ''">
                {{ 'scoring.response.label_scoring_2'  | translate }}
            </ng-container>
        </label>
        <ngb-progressbar showValue="true" type="{{score.score < 30 ? 'danger' : (score.score < 70 ? 'warning' : 'success')}}"
                         [value]="score.score" height="35px"></ngb-progressbar>
        <div class="mt-3 mb-5" *ngIf="score.advice.responso !== ''">
            <h6 class="mt-4">{{'scoring.response.final_title' | translate}}</h6>
            <p>{{score.advice.responso}}</p>
            <p>
                <b>{{'scoring.response.ricor_title' | translate}}</b>: {{score.advice.ricorribile === 0 ? 'No' : 'Sì'}}<br>
                <b>{{'scoring.response.ricor_date' | translate}}</b>: {{score.advice.data_scadenza | date: 'd/M/yyyy'}}
            </p>
        </div>

        <div class="checked-values" *ngIf="score.advice.responso === ''">
            <ul class="list-unstyled">
                <li>
                    <i-feather name="x-circle" class="text-danger" *ngIf="score.checks.datawrong"></i-feather>
                    <i-feather name="check-circle" class="text-success" *ngIf="!score.checks.datawrong"></i-feather>
                    Controllo dati anagrafici
                </li>
                <li>
                    <i-feather name="x-circle" class="text-danger" *ngIf="score.checks.dateexpired"></i-feather>
                    <i-feather name="check-circle" class="text-success" *ngIf="!score.checks.dateexpired"></i-feather>
                    Termini di consegna verbale
                </li>
                <li>
                    <i-feather name="x-circle" class="text-danger" *ngIf="score.checks.omologfail"></i-feather>
                    <i-feather name="check-circle" class="text-success" *ngIf="!score.checks.omologfail"></i-feather>
                    Difetti di omologazione e taratura
                </li>
            </ul>
        </div>
        
    `,
    styles: [`
        ngb-progressbar {
            font-weight: bold;
        }
    `]
})
export class ScoringComponent implements OnInit {
    @Input() score: Score;

    constructor() {
    }

    ngOnInit(): void {
    }

}
