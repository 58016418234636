import {LoadGuard} from './load.guard';
import {ChatbotGuard} from './chatbot.guard';
import {ScoringGuard} from './scoring.guard';
import {EmailGuard} from './email.guard';

export const guards: any[] = [LoadGuard, ChatbotGuard, ScoringGuard, EmailGuard];

export * from './load.guard';
export * from './chatbot.guard';
export * from './scoring.guard';
export * from './email.guard';
