import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {LoaderService} from '../../core/auth/services/loader.service';
import {ThemePalette} from '@angular/material/core';
import {ProgressBarMode} from '@angular/material/progress-bar';

@Component({
    selector: 'app-loader',
    template: `
        <div *ngIf="isLoading | async" class="overlay">
            <mat-progress-bar [mode]="mode" [value]="value" [color]="color">
            </mat-progress-bar>
        </div>
    `,
    styles: [`
        .overlay {
            position: fixed;
            display: block;
            width: 100%;
            height: auto;
            top: 0;
            left: 0;
            z-index: 99999;
        }

        .mat-progress-bar-fill::after {
            background-color: #DE5F1D;
        }

        .mat-progress-bar-buffer {
            background-color: #F8E4DA;
        }
    `]
})
export class LoaderComponent implements OnInit {
    color: ThemePalette = 'warn';
    mode: ProgressBarMode = 'indeterminate';
    value = 40;
    isLoading: Subject<boolean> = this.loaderService.isLoading;

    constructor(private loaderService: LoaderService) {
    }

    ngOnInit(): void {
    }

}
