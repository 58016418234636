import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {AppState} from '../core.module';
import * as AuthSelectors from '../auth/store/auth.selectors';
import * as RouterActions from '../router/store/router.actions';

@Injectable()
export class LoadGuard implements CanActivate {
    terms: boolean;

    constructor (
        private store: Store<AppState>
    ) { }

    canActivate() {
        this.store.pipe(select(AuthSelectors.getTerms)).subscribe(res => this.terms = res);
        if (!this.terms) {
            this.store.dispatch(RouterActions.go({path: ['intro']}));
            return false;
        } else {
            return true;
        }
    }

}
