import {Injectable} from '@angular/core';
import {Auth, InitAuth} from '../../../model/auth';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AuthService {

    constructor(private http: HttpClient) {}

    clear(): void {
        localStorage.clear();
    }

    setUser(init: InitAuth) {
        localStorage.setItem('username', init.username);
        localStorage.setItem('partner_id', JSON.stringify(init.partner_id));
    }

    saveAuth(auth: Auth) {
        localStorage.setItem('token', auth.access_token);
        localStorage.setItem('expires_at', auth.expires_at);
        localStorage.setItem('user_id', auth.user_id);
        this.clear();
    }

    // Da togliere in produzione insieme al reducer di saveAuth
    getAuth() {
        if (localStorage.getItem('token') !== null) {
            return {
                username: localStorage.getItem('username'),
                partner_id: JSON.parse(localStorage.getItem('partner_id')),
                user_id: localStorage.getItem('user_id'),
                access_token: localStorage.getItem('token'),
                expires_at: localStorage.getItem('expires_at')
            };
        }
    }

    setNewUser(query) {
        return this.http.put(
            `${environment.api_url}/partners/${query[1]}/multe/${query[2]}/user`,
            {data: {email: query[0].email}});
    }

}
