import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import * as braintreeActions from './braintree.actions';
import {Store} from '@ngrx/store';

@Injectable()
export class BraintreeService {
    constructor(
        private http: HttpClient,
        private store: Store
        ) {}

    getBraintreeToken(partner_id, multa_id, scoring) {
        return this.http.get<any>(
            `${environment.api_url}/partners/${partner_id}/payment/bt/token/multe/${multa_id}/${scoring}`
        );
    }

    sendBraintreePayment(partner_id, multa_id, scoring, nonce) {
        this.store.dispatch(braintreeActions.setNonce({nonce}));
        return this.http.post(
            `${environment.api_url}/partners/${partner_id}/payment/bt/sale/multe/${multa_id}/${scoring}`,
            {
                data: {
                    nonce: nonce
                }
            }
        ).subscribe();
    }
}
