import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppState} from '../../core/core.module';
import {Store} from '@ngrx/store';
import * as FineActions from '../../core/fine/store/fine.actions';

@Component({
    selector: 'app-edit-points',
    template: `
        <div class="modal-header">
            <h4 class="title-brand">Modifica punti</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-center">
            <p>Nel caso in cui i punti patente riportati non siano corretti, ti preghiamo di inserire il valore corretto riportato nel verbale.</p>
            
            <form class="w-50 m-auto" [formGroup]="pointsForm" (ngSubmit)="editPoints()">
                <input formControlName="points" type="text" class="form-control">
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-brand" [disabled]="!pointsForm.valid" (click)="editPoints()">
                Modifica
            </button>
        </div>
    `,
    styles: [``]
})
export class EditPointsComponent implements OnInit {
    @Input() points: number;

    pointsForm: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private store: Store<AppState>
        ) {
    }

    ngOnInit() {
        if (this.points === null) {
            this.points = 0;
        }

        this.pointsForm = this.fb.group({
            points: [this.points, Validators.pattern('^[0-9]*$')]
        });
    }

    editPoints() {
        const points = this.pointsForm.get('points').value;
        this.store.dispatch(FineActions.editPoints({points}));
        this.activeModal.close();
    }
}
