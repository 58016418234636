import {Component, Input, OnInit} from '@angular/core';
import {Fine, Score} from '../../model/fine';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EditPointsComponent} from './edit-points.component';
import {EditPriceComponent} from './edit-price.component';
import {TableDetailsComponent} from './table-details.component';
import {InfoDaysComponent} from './info-days.component';

@Component({
    selector: 'app-table',
    template: `
        <h5 class="title-brand text-center">{{ 'table.main_title' | translate }}</h5>

        <div class="card response-table">
            <div class="row info-row">
                <div class="col">
                    <div class="info-title">
                        {{ 'table.title_1' | translate }}
                        <!-- span class="info-link" (click)="infoDays()"><i-feather name="info" style="margin-top: 0; margin-right: 0"></i-feather></span-->
                    </div>
                    <div class="info-value" [ngClass]="days_remaining <= 0 ? 'not-app' : ''">
                        {{ this.days_remaining > 0 ? 
                                (fine.prezzo_immediato !== undefined ?
                                        '€ ' + (fine.prezzo_immediato | currency: 'EUR' : '') 
                                        : '- -'
                                ) 
                                : 'Non applicabile'
                        }}
                    </div>
                    <div class="info-btn" 
                         [ngClass]="days_remaining <= 0 ? 'd-none' : ''" 
                         (click)="editCost(fine.prezzo_immediato, fine.prezzo_normale)">
                        {{ 'table.edit_btn' | translate }} <i-feather name="edit"></i-feather>
                    </div>
                </div>
                <div class="col">
                    <div class="info-title">{{ 'table.title_2' | translate }}</div>
                    <div class="info-value">{{fine.prezzo_normale !== undefined ? '€ ' + (fine.prezzo_normale | currency: 'EUR' : '') : '- -'}}</div>
                    <div class="info-btn" (click)="editCost(fine.prezzo_immediato, fine.prezzo_normale)">{{ 'table.edit_btn' | translate }}
                        <i-feather name="edit"></i-feather>
                    </div>
                </div>
                <div class="col">
                    <div class="info-title">{{ 'table.title_3' | translate }}</div>

                    <ng-container [ngSwitch]="fine.punti">
                        <div class="info-value" *ngSwitchCase="null">- -</div>
                        <div class="info-value" *ngSwitchCase="0">{{fine.punti}}</div>
                        <div class="info-value" *ngSwitchDefault>-{{fine.punti}}</div>
                    </ng-container>
                    <div class="info-btn" (click)="editPoints(fine.punti)">{{ 'table.edit_btn' | translate }}
                        <i-feather name="edit"></i-feather>
                    </div>
                </div>
            </div>
            
            <div class="row choices">
                <div class="col">
                    <h5>Se scegli di pagare</h5>
                    <span class="info-link" (click)="showTable('pay')"><i-feather name="help-circle"></i-feather></span>
                    <div class="row" *ngIf="this.days_remaining > 0">
                        <div class="col choice">Entro il {{fine.prezzo_immediato_data_scadenza | date: 'dd/MM/yyyy'}}</div>
                        <div class="col price">
                            {{fine.prezzo_immediato !== undefined ? '€ ' + (+fine.prezzo_immediato + 2 | currency: 'EUR' : '') : '- -'}} <span *ngIf="fine.punti > 0">+ {{fine.punti}}pt</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col choice">Entro il {{fine.prezzo_normale_data_scadenza | date: 'dd/MM/yyyy'}}</div>
                        <div class="col price">
                            {{fine.prezzo_normale !== undefined ? '€ ' + (+fine.prezzo_normale + 2 | currency: 'EUR' : '') : '- -'}} <span *ngIf="fine.punti > 0">+ {{fine.punti}}pt</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row choices" *ngIf="paid">
                <div class="col">
                    <h5>Se scegli di fare ricorso</h5>
                    <span class="info-link" (click)="showTable('appeal')"><i-feather name="help-circle"></i-feather></span>
                    <p class="text-muted">{{score.ricorso.titolo}}</p>
                    <div class="row">
                        <div class="col choice">Esito positivo <b class="text-success pl-2">{{score.score}}%</b></div>
                        <div class="col price">
                            {{score.ricorso.prezzo !== undefined ? '€ ' + (+score.ricorso.prezzo | currency: 'EUR' : '') : '- -'}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col choice">Esito negativo   <b class="text-danger pl-1">{{100 - score.score}}%</b></div>
                        <div class="col price">
                            {{fine.prezzo_normale !== undefined ? '€ ' + ((+fine.prezzo_normale * 2) + (+score.ricorso.prezzo) | currency: 'EUR' : '') : '- -'}} <span *ngIf="fine.punti > 0">+ {{fine.punti}}pt</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: []
})
export class TableComponent implements OnInit {
    @Input() fine: Fine;
    @Input() score: Score;
    @Input() paid: boolean;
    days_remaining: number;

    constructor(
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        this.getDays();
    }

    getDays() {
        const final_date = new Date(this.fine.prezzo_immediato_data_scadenza);
        const today = new Date();
        this.days_remaining = Math.ceil((final_date.getTime() - today.getTime()) / (1000 * 3600 * 24));
    }

    editPoints(points) {
        const modal = this.modalService.open(EditPointsComponent, {
            centered: true
        });
        modal.componentInstance.points = points;
    }

    editCost(price_min, price_max) {
        const modal = this.modalService.open(EditPriceComponent, {
            centered: true
        });
        modal.componentInstance.price_min = price_min;
        modal.componentInstance.price_max = price_max;
    }

    showTable(type) {
        const modal = this.modalService.open(TableDetailsComponent, {
            centered: true
        });

        modal.componentInstance.type = type;
        modal.componentInstance.score = this.score;
        modal.componentInstance.fine = this.fine;
        modal.componentInstance.paid = this.paid;
        modal.componentInstance.days_remaining = this.days_remaining;
    }

    infoDays() {
        const modal = this.modalService.open(InfoDaysComponent, {
            centered: true
        });

    }

}
