import {Action, createReducer, on} from '@ngrx/store';
import * as LoadActions from '../../../features/load/store/load.actions';
import {Fine, Score} from '../../../model/fine';
import * as FineActions from './fine.actions';
import * as IntroActions from '../../../features/intro/store/intro.actions';
import * as ChatActions from '../../../features/advice/chat/store/chat.actions';
import * as ChatbotActions from '../../../features/chatbot/store/chatbot.actions';

export interface FineState {
    numero_informazioni_estratte?: number;
    loading?: boolean;
    loaded?: boolean;
    fine_id?: number;
    fine?: Fine;
    score?: Score;
    paid?: boolean;
    advice_paid?: boolean;
    paytipper_key?: string;
    paytipper_url?: string;
    resume_page?: number;
}

export const initialState: FineState = {
    score: {
        score: 0,
        livello_scoring: 0,
        checks: {
            dateexpired: true,
            datawrong: true,
            omologfail: true
        },
        ricorso: {
            acquistato: false,
            id: null,
            prezzo: null,
            titolo: '',
            verificato: false,
            richiesto: false
        },
        advice: {
            motivazione: null,
            responso: '',
            data_risposta: '',
            data_scadenza: '',
            msg_totali: 0,
            msg_non_letti: 0,
            ricorribile: 0
        }
    },
    resume_page: null
};

const fineReducer = createReducer(
    initialState,
    on(IntroActions.initApp, () => ({
        numero_informazioni_estratte: 2,
        score: {
            score: 0,
            livello_scoring: 0,
            checks: {
                dateexpired: true,
                datawrong: true,
                omologfail: true
            },
            ricorso: {
                acquistato: false,
                id: null,
                prezzo: null,
                titolo: '',
                verificato: false,
                richiesto: false
            },
            advice: {
                motivazione: null,
                responso: '',
                data_risposta: '',
                data_scadenza: '',
                msg_totali: 0,
                msg_non_letti: 0,
                ricorribile: 0
            }
        },
        paid: false,
        advice_paid: false,
        resume_page: null
    })),
    on(LoadActions.uploadFineDone, (state, action) => ({
        ...state,
        fine_id: action.multa_id,
        loading: true
    })),
    // Uguale a LoadActions.uploadFineDone
    on(FineActions.saveFineDataResume, (state, action) => ({
        ...state,
        fine_id: action.fine_id,
        loading: true,
        resume_page: action.page
    })),
    on(LoadActions.fineExtracted, (state, action) => ({
        ...state,
        fine: action.fine.multa,
        numero_informazioni_estratte: action.fine.numero_informazioni_estratte,
        loading: true
    })),
    on(ChatbotActions.setOwnershipSuccess, (state, action) => ({
        ...state,
        fine: {
            ...state.fine,
            ownership: action.ownership
        }
    })),
    on(LoadActions.analyzingFine, (state) => ({
        ...state,
        loading: false,
        loaded: true
    })),
    on(FineActions.getFineDataSuccess, (state, action) => ({
        ...state,
        fine: action.fine
    })),
    on(FineActions.doubleCheckSuccess, (state, action) => ({
        ...state,
        paid: action.paid
    })),
    on(ChatbotActions.msgSent, (state, action) => ({
        ...state,
        paid: action.chat.payload.type === 'score'
    })),
    on(FineActions.getScoreDataSuccess, (state, action) => ({
        ...state,
        score: {
            ...action.score,
            advice: action.score.advice ? action.score.advice : state.score.advice,
            checks: action.score.checks ? action.score.checks : state.score.checks,
            ricorso: action.score.ricorso ? action.score.ricorso : state.score.ricorso
        },
        paid: true
    })),
    on(FineActions.resumeScoringOne, (state) => ({
        ...state,
        paid: true,
        loaded: true
    })),
    on(FineActions.resumeDirectToAdvice, (state) => ({
        ...state,
        loaded: true
    })),
    on(FineActions.editPointsSuccess, (state, action) => ({
        ...state,
        fine: {
            ...state.fine,
            punti: +action.data[0].points
        }
    })),
    on(FineActions.editPriceSuccess, (state, action) => ({
        ...state,
        fine: {
            ...state.fine,
            prezzo_normale: +action.prezzo_normale,
            prezzo_immediato: +action.prezzo_immediato
        }
    })),
    on(FineActions.getPayTipperKeySuccess, (state, action) => ({
        ...state,
        paytipper_key: action.key,
        paytipper_url: action.url
    })),
    on(FineActions.confirmAppealSuccess, (state) => ({
        ...state,
        score: {
            ...state.score,
            ricorso: {
                ...state.score.ricorso,
                richiesto: true
            }
        }
    })),
    on(FineActions.checkPaidAdviceSuccess, (state, action) => ({
        ...state,
        score: {
            ...state.score
        },
        advice_paid: action.paid
    })),
    on(FineActions.sendReasonSuccess, (state, action) => ({
        ...state,
        score: {
            ...state.score,
            advice: {
                motivazione: action.reason,
                responso: '',
                data_risposta: '',
                data_scadenza: '',
                msg_totali: 0,
                msg_non_letti: 0,
                ricorribile: 0
            }
        }
    })),
    on(ChatActions.markAllRedSuccess, (state) => ({
        ...state,
        score: {
            ...state.score,
            advice: {
                ...state.score.advice,
                msg_non_letti: 0
            }
        }
    })),
    on(ChatbotActions.setOwnershipRented, (state) => ({
        ...state,
        loaded: true
    }))
);

export function reducer(state: FineState | undefined, action: Action) {
    return fineReducer(state, action);
}
