import {Action, createReducer, on} from '@ngrx/store';
import * as ChatbotActions from './chatbot.actions';
import * as FineActions from '../../../core/fine/store/fine.actions';

export interface ChatbotState {
    messages?: any;
    sessionId?: number;
    loader?: boolean;
    ended?: boolean;
}

export const initialState: ChatbotState = {};

const chatbotReducer = createReducer(
    initialState,
    on(ChatbotActions.loadChatbotSuccess, (state, action) => ({
        ...state,
        messages: [action.chat],
        sessionId: action.chat.sessionId,
        loader: false
    })),
    on(ChatbotActions.sendMsg, (state, action) => ({
        ...state,
        messages: [...state.messages, {
            status: false,
            richResponse: false,
            payload: {
                type: 'text',
                options: {
                    text: [action.msg]
                }
            }
        }],
        loader: true
    })),
    on(ChatbotActions.changeParam, (state, action) => ({
        ...state,
        messages: [...state.messages, {
            status: false,
            richResponse: false,
            payload: {
                type: 'text',
                options: {
                    text: [action.msg.value]
                }
            }
        }],
        loader: false
    })),
    on(ChatbotActions.msgSent, (state, action) => ({
        ...state,
        messages: [...state.messages, action.chat],
        loader: false
    })),
    on(ChatbotActions.chatEnd, (state) => ({
        ...state,
        ended: true
    })),
    on(FineActions.resumeScoringOne, (state) => ({
        ...state,
        ended: true
    })),
    on(ChatbotActions.setOwnershipRented, (state) => ({
        ...state,
        ended: true
    })),
    on(FineActions.resumeDirectToAdvice, (state) => ({
        ...state,
        ended: true
    })),
);

export function reducer(state: ChatbotState | undefined, action: Action) {
    return chatbotReducer(state, action);
}
