import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import * as guards from './core/guard';

const routes: Routes = [
    {
        path: 'intro',
        loadChildren: () => import('./features/intro/intro.module').then(m => m.IntroModule)
    },
    {
        path: '', canActivate: [guards.LoadGuard], children: [
            {
                path: 'load',
                loadChildren: () => import('./features/load/load.module').then(m => m.LoadModule)
            },
            {
                path: 'rent',
                loadChildren: () => import('./features/rent/rent.module').then(m => m.RentModule)
            },
            {
                path: 'rent/email',
                loadChildren: () => import('./features/rent/email/email.module').then(m => m.EmailModule)
            },
            {
                path: '', canActivate: [guards.ChatbotGuard], children: [
                    {
                        path: 'chatbot',
                        loadChildren: () => import('./features/chatbot/chatbot.module').then(m => m.ChatbotModule)
                    },
                    {
                        path: '', canActivate: [guards.ScoringGuard], children: [
                            {
                                path: 'scoring/option',
                                loadChildren: () => import('./features/scoring/option/option.module').then(m => m.OptionModule)
                            },
                            {
                                path: 'scoring/email',
                                loadChildren: () => import('./features/scoring/email/email.module').then(m => m.EmailModule)
                            },
                            {
                                path: '', canActivate: [guards.EmailGuard], children: [
                                    {
                                        path: 'scoring/payment',
                                        loadChildren: () => import('./features/scoring/payment/payment.module').then(m => m.PaymentModule)
                                    },
                                    {
                                        path: 'scoring/response',
                                        loadChildren: () => import('./features/scoring/response/response.module').then(m => m.ResponseModule)
                                    },
                                    {
                                        path: 'advice/info',
                                        loadChildren: () => import('./features/advice/info/info.module').then(m => m.InfoModule)
                                    },
                                    {
                                        path: 'advice/payment',
                                        loadChildren: () => import('./features/advice/payment/payment.module').then(m => m.PaymentModule)
                                    },
                                    {
                                        path: 'advice/request',
                                        loadChildren: () => import('./features/advice/request/request.module').then(m => m.RequestModule)
                                    },
                                    {
                                        path: 'advice/chat',
                                        loadChildren: () => import('./features/advice/chat/chat.module').then(m => m.ChatModule)
                                    }
                                ]
                            },
                            {
                                path: 'scoring/info',
                                loadChildren: () => import('./features/scoring/info/info.module').then(m => m.InfoModule)
                            }
                        ]
                    },
                    {
                        path: 'payfine/resume',
                        loadChildren: () => import('./features/payfine/resume/resume.module').then(m => m.ResumeModule)
                    },
                    {
                        path: 'payfine/choice',
                        loadChildren: () => import('./features/payfine/choice/choice.module').then(m => m.ChoiceModule)
                    }
                ]
            }
        ]

    },
    {
        path: 'thank-you',
        loadChildren: () => import('./features/thank-you/thank-you.module').then(m => m.ThankYouModule)
    },
    {
        path: 'ricorso',
        loadChildren: () => import('./features/thank-you-ricorso/thank-you-ricorso.module').then(m => m.ThankYouRicorsoModule)
    },
    {
        path: 'resume/p/:partner_id/m/:fine_id/e/:email/t/:page',
        loadChildren: () => import('./features/resume/resume.module').then(m => m.ResumeModule)
    },
    {
        path: 't24/e/:email',
        loadChildren: () => import('./features/t24/t24.module').then(m => m.T24Module)
    },
    {
        path: 'error',
        loadChildren: () => import('./features/error/error.module').then(m => m.ErrorModule)
    },
    {path: '**', redirectTo: 'intro'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
