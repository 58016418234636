import {createAction, props} from '@ngrx/store';
import {ErrorModel} from '../../../model/error';

export const error500 = createAction(
    '[Error] Error 500',
    props<{error: ErrorModel}>()
);

export const lightError = createAction(
    '[Error] Light Error',
    props<{error: ErrorModel}>()
);

export const badError = createAction(
    '[Error] Error with redirection'
);

export const resetError = createAction(
    '[Error] Reset'
);
