import {createAction, props} from '@ngrx/store';
import {Fine} from '../../../model/fine';

export const getFineData = createAction(
    '[Fine] Get fine data'
);

export const getFineDataNoPayment = createAction(
    '[Fine] Get fine data before payment'
);

// SCORING 1: CONTROLLO PAGAMENTO
export const checkPaidScoring = createAction(
    '[Advice] Check scoring payment'
);

export const doubleCheckScoringOne = createAction(
    '[Advice] Double check scoring one payment'
);

export const doubleCheckSuccess = createAction(
    '[Advice] Double check scoring one payment success',
    props<{paid: boolean}>()
);

export const doubleCheckFailed = createAction(
    '[Advice] Double check scoring one payment failed'
);

// RESUME
export const saveFineDataResume = createAction(
    '[Fine] Save fine data for resuming',
    props<{fine_id: number, page: number}>()
);

export const getFineDataResume = createAction(
    '[Fine] Get fine data for resuming'
);

export const resumePage = createAction(
    '[Fine] Resuming page'
);

export const resumeChatbot = createAction(
    '[Fine] Resume Chatbot'
);

export const resumeScoringOne = createAction(
    '[Fine] Resume Scoring'
);

export const resumeDirectToAdvice = createAction(
    '[Fine] Resume direct to Advice'
);

// END RESUME

export const getFineDataSuccess = createAction(
    '[Fine] Get fine data success',
    props<{fine: Fine}>()
);

export const getFineDataFailed = createAction(
    '[Fine] Get fine data failed'
);

export const getScoreData = createAction(
    '[Fine] Get score data'
);

export const getScoreDataSuccess = createAction(
    '[Fine] Get score data success',
    props<{score: any}>()
);

export const getScoreDataFailed = createAction(
    '[Fine] Get score data failed'
);

export const editPoints = createAction(
    '[Fine] Edit Points',
    props<{points: number}>()
);

export const editPointsSuccess = createAction(
    '[Fine] Edit Points success',
    props<{data: any}>()
);

export const editPointsFailed = createAction(
    '[Fine] Edit Points failed'
);

export const editPrice = createAction(
    '[Fine] Edit Price',
    props<{price_min: number, price_max: number}>()
);

export const editPriceSuccess = createAction(
    '[Fine] Edit Price success',
    props<{status: string, prezzo_normale: number, prezzo_immediato: number}>()
);

export const editPriceFailed = createAction(
    '[Fine] Edit Price failed'
);

export const getPayTipperKey = createAction(
    '[PayTipper] Get Key',
    props<{choice: string}>()
);

export const getPayTipperKeySuccess = createAction(
    '[PayTipper] Get Key Success',
    props<{key: string, url: string}>()
);

export const getPayTipperKeyFailed = createAction(
    '[PayTipper] Get Key Failed'
);

export const goToPaytipper = createAction(
    '[PayTipper] Save Name',
    props<{url: string, payload: any}>()
);

export const goToPaytipperSuccess = createAction(
    '[PayTipper] Go to Paytipper',
    props<{url: string, payload: any}>()
);

export const goToPaytipperFailed = createAction(
    '[PayTipper] Go to Paytipper Failed'
);

// RICHIEDI RICORSO
export const confirmAppeal = createAction(
    '[Appeal] Confirm appeal'
);

export const confirmAppealSuccess = createAction(
    '[Appeal] Confirm appeal success'
);

export const confirmAppealFailed = createAction(
    '[Appeal] Confirm appeal failed'
);

// SCORING 2: CONTROLLO PAGAMENTO
export const checkPaidAdvice = createAction(
    '[Advice] Check advice payment'
);

export const checkPaidAdviceSuccess = createAction(
    '[Advice] Advice paid',
    props<{paid: boolean}>()
);

export const checkPaidAdviceFailed = createAction(
    '[Advice] Advice unpaid'
);


// SCORING 2: INVIO MOTIVAZIONE
export const sendReason = createAction(
    '[Advice] Send reason',
    props<{reason: any}>()
);

export const sendReasonSuccess = createAction(
    '[Advice] Send reason success',
    props<{reason: any}>()
);

export const sendReasonFailed = createAction(
    '[Advice] Send reason failed'
);
