import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterReducerState} from '@ngrx/router-store';
import {AppState} from '../../core.module';

export const getRouter = createFeatureSelector<any, RouterReducerState>('router');

export const getRouterState = (state: AppState) => state.router;

export const getActivePage = createSelector(
    getRouterState,
    (state: RouterReducerState) => state.state.url
);
