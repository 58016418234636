import {NgModule} from '@angular/core';
import {FeatherModule} from 'angular-feather';
import {
    Facebook,
    Menu,
    Bell,
    User,
    Settings,
    MoreHorizontal,
    LogOut,
    Home,
    Share,
    Archive,
    Truck,
    ChevronRight,
    ChevronLeft,
    ChevronUp,
    ChevronDown,
    Check,
    HelpCircle,
    MessageCircle,
    MessageSquare,
    Trash,
    Trash2,
    Send,
    XCircle,
    X,
    CheckCircle,
    Edit,
    Info,
    Paperclip,
    ArrowLeftCircle,
    FileText,
    Download
} from 'angular-feather/icons';

const icons = {
    Facebook,
    Menu,
    Bell,
    User,
    Settings,
    MoreHorizontal,
    LogOut,
    Home,
    Share,
    Archive,
    Truck,
    ChevronRight,
    ChevronLeft,
    ChevronUp,
    ChevronDown,
    Check,
    HelpCircle,
    MessageCircle,
    MessageSquare,
    Trash,
    Trash2,
    Send,
    XCircle,
    X,
    CheckCircle,
    Edit,
    Info,
    Paperclip,
    ArrowLeftCircle,
    FileText,
    Download
};

@NgModule({
    declarations: [],
    imports: [
        FeatherModule.pick(icons)
    ],
    exports: [
        FeatherModule
    ]
})
export class IconsModule {
}
