import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../core.module';
import * as RouterActions from '../../router/store/router.actions';
import * as FineSelectors from '../../fine/store/fine.selectors';
import * as RouterSelectors from '../../router/store/router.selectors';
import {RoutingState} from '../../router/routingState';

@Component({
    selector: 'app-header',
    template: `
        <div class="main">
            <nav class="navbar d-flex flex-row justify-content-between">
                <div>
                    <div class="navbar-brand">
                        <img src="../../../../assets/images/logo.png" alt="" class="d-inline-block logo">
                        <span class="beta">Beta</span>
                    </div>
                </div>
                <div class="flaglang">
                    <ng-container *ngIf="(currentUrl$ | async) !== '/advice/chat'">
                        <a (click)="goToChat()" class="msg-icon" *ngIf="(totalMessages$ | async) > 0">
                            <i-feather name="message-square"></i-feather>
                            <span class="badge badge-warning" *ngIf="(unreadMessages$ | async) > 0">{{unreadMessages$ | async}}</span>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="(currentUrl$ | async) === '/advice/chat'">
                        <a (click)="backToScoring()" class="back-icon">
                            <i-feather name="chevron-left"></i-feather>
                        </a>
                    </ng-container>
                    <a (click)="changeLang()" class="d-none">
                        <span class="flag-icon" [ngClass]="getLang()"></span>
                    </a>
                </div>
            </nav>
        </div>
    `,
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    totalMessages$ = this.store.pipe(select(FineSelectors.getTotalMessages));
    unreadMessages$ = this.store.pipe(select(FineSelectors.getUnreadMessages));
    currentUrl$ = this.store.pipe(select(RouterSelectors.getActivePage));

    previousRoute: string;

    constructor(
        private translate: TranslateService,
        private store: Store<AppState>,
        private routingState: RoutingState
    ) {
        routingState.loadRouting();
    }

    getLang() {
        return this.translate.currentLang === 'it' ? 'flag-icon-gb' : 'flag-icon-it';
    }

    changeLang() {
        let newLang = '';
        if (this.translate.currentLang === 'it') {
            newLang = 'en';
        } else {
            newLang = 'it';
        }
        this.translate.use(newLang);
        this.getLang();
    }

    goToChat() {
        this.store.dispatch(RouterActions.go({path: ['advice/chat']}));
    }

    backToScoring() {
        this.previousRoute = this.routingState.getPreviousUrl();
        this.store.dispatch(RouterActions.go({path: [this.previousRoute] }));
    }

}
