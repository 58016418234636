import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptojsService {

    SECRET = 'T14$$1$to_mult3$!';

    constructor() {
    }

    signRequest(data) {
        let json_escaped;
        if (data === '') {
            json_escaped = JSON.stringify(data);
        } else {
            const json = JSON.stringify(data);
            json_escaped = json.replace(
                /[\u007F-\uFFFF]/g,
                function (chr) {
                    return '\\u' + ('0000' + chr.charCodeAt(0).toString(16)).substr(-4);
                });
        }
        const hash = CryptoJS.HmacSHA256(json_escaped, this.SECRET);
        return hash.toString(CryptoJS.enc.Base64);
    }

}
