import {catchError, exhaustMap, filter, mapTo, switchMap, switchMapTo, withLatestFrom} from 'rxjs/operators';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import * as AuthActions from './auth.actions';
import {select, Store} from '@ngrx/store';
import * as AuthSelectors from './auth.selectors';
import * as FineSelectors from '../../fine/store/fine.selectors';
import {AppState} from '../../core.module';
import {of} from 'rxjs';
import * as FineActions from '../../fine/store/fine.actions';
import * as ChatbotActions from '../../../features/chatbot/store/chatbot.actions';
import * as RouterActions from '../../router/store/router.actions';

@Injectable()
export class AuthEffects {

    initEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            mapTo(this.authService.getAuth()),
            filter(auth => !!auth),
            switchMap(auth => [
                AuthActions.saveAuth({auth})
            ])
        )
    );

    setUserEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(AuthActions.setNewUser),
            switchMap(action => of(action).pipe(
                withLatestFrom(
                    this.store.pipe(select(AuthSelectors.getPartnerId)),
                    this.store.pipe(select(FineSelectors.getFineId))
                )
            )),
            exhaustMap(query => this.authService.setNewUser(query)
                    .pipe(
                        switchMap((res) => [
                            AuthActions.setNewUserSuccess({user: [query, res]}),
                            FineActions.checkPaidScoring()
                            ]),
                        catchError(() => of(AuthActions.setNewUserFailed()))
                    )
            )
        )
    );

    setUserRentedEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(AuthActions.setNewUserRented),
            switchMap(action => of(action).pipe(
                withLatestFrom(
                    this.store.pipe(select(AuthSelectors.getPartnerId)),
                    this.store.pipe(select(FineSelectors.getFineId))
                )
            )),
            exhaustMap(query => this.authService.setNewUser(query)
                .pipe(
                    switchMap((res) => [
                        AuthActions.setNewUserSuccess({user: [query, res]}),
                        ChatbotActions.setOwnershipRented(),
                        FineActions.checkPaidAdvice()
                    ]),
                    catchError(() => of(AuthActions.setNewUserFailed()))
                )
            )
        )
    );


    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private authService: AuthService
    ) {
    }
}
