import {createAction, props} from '@ngrx/store';

export const loadSignature = createAction(
    '[Load] Load signature',
    props<{data: any}>()
);

export const analyzingFine = createAction(
    '[Load] Loading fine'
);

export const uploadFineDone = createAction(
    '[Load] Load fine done',
    props<{multa_id: number, url: any}>()
);

export const fineExtracted = createAction(
    '[Load] Fine extracted',
    props<{fine: any}>()
);

export const fineNotExtracted = createAction(
    '[Load] Fine not extracted'
);
