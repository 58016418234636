import { Component } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-days',
  template: `
      <div class="modal-header">
          <h4 class="title-brand">Perché 30 giorni?</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <p>In base al Dl Rilancio, fino al 31 luglio del 2020 la somma dovuta sarà ridotta del 30% se il pagamento viene effettuato entro 30 giorni dalla contestazione o notificazione della violazione.</p>
      </div>
  `,
  styles: []
})
export class InfoDaysComponent {

  constructor(public activeModal: NgbActiveModal) { }


}
