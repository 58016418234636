import {AppState} from '../../core.module';
import {createSelector} from '@ngrx/store';
import {FineState} from './fine.reducer';

export const selectFine = (state: AppState) => state.fine;

export const getFineId = createSelector(
    selectFine,
    (state: FineState) => state.fine_id
);

export const getLoadingState = createSelector(
    selectFine,
    (state: FineState) => state.loading
);

export const getLoadedState = createSelector(
    selectFine,
    (state: FineState) => state.loaded
);

export const getFineData = createSelector(
    selectFine,
    (state: FineState) => state.fine
);

export const getExtractedInfos = createSelector(
    selectFine,
    (state: FineState) => state.numero_informazioni_estratte
);

export const getScoreData = createSelector(
    selectFine,
    (state: FineState) => state.score
);

export const getPaid = createSelector(
    selectFine,
    (state: FineState) => state.paid
);

export const getPayTipperKey = createSelector(
    selectFine,
    (state: FineState) => state.paytipper_key
);

export const getPayTipperUrl = createSelector(
    selectFine,
    (state: FineState) => state.paytipper_url
);

export const getResumePage = createSelector(
    selectFine,
    (state: FineState) => state.resume_page
);

export const getAppealRequested = createSelector(
    selectFine,
    (state: FineState) => state.score.ricorso.richiesto
);

export const getAdvicePayed = createSelector(
    selectFine,
    (state: FineState) => state.advice_paid
);

export const getAdvice = createSelector(
    selectFine,
    (state: FineState) => state.score.advice
);

export const getAdviceResponse = createSelector(
    selectFine,
    (state: FineState) => state.score.advice.responso
);

export const getTotalMessages = createSelector(
    selectFine,
    (state: FineState) => state.score.advice.msg_totali
);

export const getUnreadMessages = createSelector(
    selectFine,
    (state: FineState) => state.score.advice.msg_non_letti
);

export const getOwnership = createSelector(
    selectFine,
    (state: FineState) => state.fine.ownership
);
