import {Action, createReducer, on} from '@ngrx/store';
import * as ChatActions from './chat.actions';
import {Chat} from '../../../../model/chat';

export interface ChatState {
    messages?: any;
}

export const initialState: ChatState = {};

const chatReducer = createReducer(
    initialState,
    on(ChatActions.loadChatSuccess, (state, action) => ({
        ...state,
        messages: action.chat.messaggi
    })),
    on(ChatActions.msgSent, (state, action) => ({
        ...state,
        messages: [
            ...state.messages,
            action.chat.messaggio
        ]
    })),
    on(ChatActions.getImagesUrlSuccess, (state, action) => ({
        ...state,
        messages: state.messages.map((message: Chat) => message.ha_allegati ? {
            ...message,
            allegati_url: message.allegati.map(
                allegato_id => action.payload.urls.find(
                    url_obj => url_obj.id === allegato_id
                )
            )
        } : {...message})
    })),
    on(ChatActions.getMsgImagesUrlSuccess, (state, action) => ({
        ...state,
        messages: state.messages.map((message: Chat) => message.ha_allegati ? {
            ...message,
            allegati_url: message.allegati.map(
                (allegato_id, i) => {
                    const url = action.payload.urls.find(
                        url_obj => url_obj.id === allegato_id
                    );
                    if (!url) {
                        return message.allegati_url[i];
                    } else {
                        return url;
                    }
                }
            )
        } : {...message})
    }))
);


export function reducer(state: ChatState | undefined, action: Action) {
    return chatReducer(state, action);
}
