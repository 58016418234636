import {Component, Input, OnInit} from '@angular/core';
import {HttpStatus} from './store/http-status.reducer';
import {Store} from '@ngrx/store';
import * as errorActions from './store/http-status.actions';

@Component({
    selector: 'app-http-status',
    template: `
        <div class="httpContainer">
            <div
                    *ngIf="httpStatus.status === 'ko'" 
                    class="httpstatus alert" 
                    [ngClass]="(httpStatus.gravity=='danger') ? 'alert-danger' : 'alert-warning'"
            >
                {{ ('error.' + httpStatus.type) | translate: {Default: "Siamo spiacenti, c'è stato un errore."} }}
                <span *ngIf="httpStatus.closeBtn" class="float-right remove-alert" (click)="removeAlert()">
                    <i-feather name="x-circle"></i-feather>
                </span>
            </div>
        </div>
    `,
    styles: [`
        .httpContainer {
            position: absolute;
            width: 100%;
            height: auto;
            margin: 0 auto;
            padding: 0 15px;
            z-index: 100;
        }
        
        .httpstatus {
            position: relative;
            margin: 26px auto;
        }
        .remove-alert {
            cursor: pointer;
            transition: all .25s;
        }
        .remove-alert:hover {
            opacity: .7;
        }
    `]
})
export class HttpStatusComponent implements OnInit {
    @Input() httpStatus: HttpStatus;

    constructor(
        private store: Store,
    ) {
    }

    ngOnInit(): void {
    }

    removeAlert() {
        this.store.dispatch(errorActions.resetError());
    }

}
