import {createAction, props} from '@ngrx/store';
import {ChatbotMessage} from '../../../model/chatbot-message';

export const loadChatbot = createAction(
    '[Bot] Init bot',
    props<{ partner_id: number, fine_id: number }>()
);

export const loadChatbotSuccess = createAction(
    '[Bot] Init bot success',
    props<{ chat: ChatbotMessage<any> }>()
);

export const loadChatbotFailed = createAction(
    '[Bot] Init bot failed'
);

export const sendMsg = createAction(
    '[User] Send user message',
    props<{ msg: string }>()
);

export const msgSent = createAction(
    '[Bot] Message sent',
    props<{ chat: ChatbotMessage<any> }>()
);

export const msgUnsent = createAction(
    '[Bot] Message unsent'
);

export const chatSignature = createAction(
    '[Bot] Signature',
    props<{ msg: string, partner_id: number, chat_id: number, session_id: number }>()
);

export const sendArticles = createAction(
    '[Bot] Send articles',
    props<{ msg: string }>()
);

export const changeParam = createAction(
    '[Bot] Change Parameter',
    props<{ msg: any }>()
);

export const chatEnd = createAction(
    '[Bot] Chat end'
);

export const setOwnership = createAction(
    '[Ownership] Ownership',
    props<{ownership: number}>()
);

export const setOwnershipRent = createAction(
    '[Ownership] Ownership rent',
    props<{ownership: number}>()
);

export const setOwnershipRented = createAction(
    '[Ownership] Ownership rented'
);

export const setOwnershipOther = createAction(
    '[Ownership] Ownership other',
    props<{ownership: number}>()
);

export const setOwnershipSuccess = createAction(
    '[Ownership] Ownership success',
    props<{ownership: number}>()
);

export const setOwnershipFailed = createAction(
    '[Ownership] Ownership failed'
);
