import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as RouterActions from '../../core/router/store/router.actions';
import * as errorActions from '../../core/httpStatus/store/http-status.actions';

@Component({
    selector: 'app-error',
    template: `
        <h3 class="text-brand mb-3 mt-4">{{ 'error.main_title'  | translate }}</h3>
        <p class="mb-5">{{ 'error.main_text'  | translate }}</p>
        <button class="btn btn-submit" (click)="goToIntro()">
            {{'error.btn' | translate}}
        </button>
    `,
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    constructor(
        private store: Store
    ) {
    }

    ngOnInit(): void {
    }

    goToIntro() {
        this.store.dispatch(errorActions.resetError());
        this.store.dispatch(RouterActions.go({path: ['intro']}));
    }

}
