import {createAction, props} from '@ngrx/store';
import {Auth, InitAuth} from '../../../model/auth';

export const initApp = createAction(
    '[Intro] Init',
    props<{ init: InitAuth }>()
);

export const initAppResume = createAction(
    '[Intro] Init resume',
    props<{ init: InitAuth }>()
);

export const initT24 = createAction(
    '[Intro] Init T24',
    props<{ init: InitAuth }>()
);

export const initAppSuccess = createAction(
    '[Intro] Init success',
    props<{auth: Auth}>()
);

export const initAppFailed = createAction(
    '[Intro] Init failed'
);

export const terms = createAction(
    '[Intro] Terms',
    props<{terms: boolean}>()
);
